import React from 'react';
import PropTypes from 'prop-types';
import Image from '../generic/Image';
import { recipeIsPartOfMenuShape } from './shapes';

if (process.env.CLIENT) {
  require('./IsFromMenu.scss'); // eslint-disable-line global-require
}

const IsFromMenu = ({ recipeIsPartOfMenu }) => (
  <div className="menu-is-part-of-wrapper gel-wrap">
    <h1 className="menu-is-part-of__header gel-great-primer">Try this as part of this menu</h1>

    <div className="menu-is-part-of__list">
      <div className="menu-is-part-of__dish gel-1/1">
        <a
          className="menu-is-part-of__title-link gel-1/1 gel-pica-bold"
          href={`/food/menus/${recipeIsPartOfMenu.id}`}
        >
          <div className="menu-is-part-of__image-container gel-1/1">
            <Image
              className="menu-is-part-of__image gel-1/1"
              src={`food/ic/food_16x9_{width}/recipes/${recipeIsPartOfMenu.recipes[0].id}_16x9.jpg`}
            />
          </div>
          <div className="menu-is-part-of__information">
            <div className="menu-is-part-of__title">{recipeIsPartOfMenu.title}</div>
          </div>
        </a>
      </div>

      {recipeIsPartOfMenu.recipes.map(recipe => (
        <div className="menu-is-part-of__dish gel-1/1 gel-1/2@m gel-1/1@l" key={recipe.id}>
          <a
            className="menu-is-part-of__title-link gel-1/1 gel-pica-bold"
            href={`/food/recipes/${recipe.id}`}
          >
            {recipe.hasImage === 'true' && (
              <div className="menu-is-part-of__image-container gel-1/2 gel-1/1@m gel-1/2@l">
                <Image
                  className="menu-is-part-of__image gel-1/1"
                  src={`food/ic/food_16x9_{width}/recipes/${recipe.id}_16x9.jpg`}
                />
              </div>
            )}
            <div className="menu-is-part-of__information">
              <div className="menu-is-part-of__title">{recipe.title}</div>
              {recipe.course && (
                <div className="menu-is-part-of__subtitle gel-long-primer">
                  {recipe.course.title}
                </div>
              )}
              {recipe.chef && (
                <div className="menu-is-part-of__subtitle gel-long-primer">{`by ${recipe.chef.displayName}`}</div>
              )}
            </div>
          </a>
        </div>
      ))}
      <div className="menu-is-part-of__medium-grid-separator" />
    </div>
  </div>
);

IsFromMenu.propTypes = {
  recipeIsPartOfMenu: PropTypes.shape(recipeIsPartOfMenuShape),
};

IsFromMenu.defaultProps = {
  recipeIsPartOfMenu: undefined,
};

export default IsFromMenu;
