import React from 'react';
import PropTypes from 'prop-types';

import { methodsShape } from './shapes';

if (process.env.CLIENT) {
  require('./MethodsList.scss'); // eslint-disable-line global-require
}

const MethodsList = ({ methods }) => (
  <div className="recipe-method gel-layout__item">
    <div className="recipe-method-wrapper">
      <h2 className="recipe-method__heading gel-double-pica">Method</h2>

      <ol className="recipe-method__list">
        {methods.map(({ text }, i) => (
          // eslint-disable-next-line
          <li key={`method-${i}`} className="recipe-method__list-item">
            <p
              className="recipe-method__list-item-text"
              dangerouslySetInnerHTML={{ __html: text }}
            />
          </li>
        ))}
      </ol>
    </div>
  </div>
);

MethodsList.propTypes = {
  methods: PropTypes.arrayOf(methodsShape).isRequired,
};

export default MethodsList;
