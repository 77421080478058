/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import RateRecipeCta from './RateRecipeCta';
import RatingsTooltip from './RatingsTooltip';
import AggregateRating from './AggregateRating';
import { RATING_STATUS } from './constants';
import { toggleRatings, hideRatings, rate, getRatings } from './actions';
import IdctaTooltip from '../../generic/idcta/Tooltip';

if (process.env.CLIENT) {
  require('./Ratings.scss'); // eslint-disable-line global-require
}

export class Ratings extends Component {
  ratingsRef = React.createRef();

  componentDidMount() {
    this.props.getRatings(this.props.recipeId);
    document.addEventListener('mousedown', this.focusOut);
    document.addEventListener('touchend', this.focusOut);
    document.addEventListener('focusout', this.focusOut);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.focusOut);
    document.removeEventListener('touchend', this.focusOut);
    document.removeEventListener('focusout', this.focusOut);
  }

  focusOut = ev => {
    if (
      (this.props.showRatingsTooltip || this.props.showSignInTooltip) &&
      this.ratingsRef.current &&
      !this.ratingsRef.current.contains(ev.relatedTarget || ev.target)
    ) {
      if (this.props.showRatingsTooltip) {
        this.hideRatingStars();
      } else {
        this.closeIdctaTooltip();
      }
    }
  };

  setUserRating = rating => {
    this.props.rate(this.props.recipeId, rating);
    this.props.hideRatings();
  };

  hideRatingStars = () => {
    this.props.hideRatings();
  };

  openRatings = () => {
    this.props.toggleRatings();
  };

  handleIdctaSignIn = event => {
    event.preventDefault();
    const { href } = event.currentTarget;
    window.location.href = href;
  };

  handleIdctaRegister = event => {
    event.preventDefault();
    const { href } = event.currentTarget;
    window.location.href = href;
  };

  closeIdctaTooltip = () => {
    this.props.hideRatings();
  };

  render() {
    const { isAMP, showRatingsTooltip, showSignInTooltip, status } = this.props;
    const rating = isAMP ? this.props.cachedRating : this.props.rating;
    return (
      <div className="recipe-ratings">
        {rating && rating.total > 0 && (
          <AggregateRating rating={Math.round(rating.value * 2) / 2} total={rating.total} />
        )}

        {!isAMP && (
          <div ref={this.ratingsRef}>
            {status !== RATING_STATUS.ERROR && (
              <RateRecipeCta
                onClick={this.openRatings}
                status={status}
                firstRating={rating && rating.total === 0}
              />
            )}
            {showRatingsTooltip && (
              <RatingsTooltip
                submitRating={this.setUserRating}
                closeTooltip={this.hideRatingStars}
              />
            )}
            {showSignInTooltip && (
              <div
                className={classNames('recipe-ratings__signin-wrapper', {
                  lower: rating && rating.total > 0,
                })}
              >
                <IdctaTooltip
                  onSignIn={this.handleIdctaSignIn}
                  onRegister={this.handleIdctaRegister}
                  onClose={this.closeIdctaTooltip}
                  subtitle="Sign in or Register to rate this recipe."
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

Ratings.propTypes = {
  isAMP: PropTypes.bool,
  showRatingsTooltip: PropTypes.bool,
  showSignInTooltip: PropTypes.bool,
  toggleRatings: PropTypes.func.isRequired,
  hideRatings: PropTypes.func.isRequired,
  status: PropTypes.oneOf(Object.values(RATING_STATUS)).isRequired,
  recipeId: PropTypes.string.isRequired,
  rate: PropTypes.func.isRequired,
  getRatings: PropTypes.func.isRequired,
  rating: PropTypes.shape({
    total: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  }),
  cachedRating: PropTypes.shape({
    total: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  }),
};

Ratings.defaultProps = {
  isAMP: false,
  showRatingsTooltip: false,
  showSignInTooltip: false,
  rating: null,
  cachedRating: null,
};

export default connect(
  ({ recipeReducer, ratingsReducer }) => ({
    showRatingsTooltip: ratingsReducer.showRatingsTooltip,
    showSignInTooltip: ratingsReducer.showSignInTooltip,
    status: ratingsReducer.status,
    rating: ratingsReducer.rating,
    cachedRating: recipeReducer.rating,
  }),
  {
    toggleRatings,
    hideRatings,
    rate,
    getRatings,
  }
)(Ratings);
