import React from 'react';
import PropTypes from 'prop-types';
import Image from '../generic/Image';

if (process.env.CLIENT) {
  require('./IsFromProgramme.scss'); // eslint-disable-line global-require
}

const IsFromProgramme = ({
  programmeEpisode,
  programmeSeries,
  programmeTopLevel,
  recipesFromThisEpisode,
  title,
}) => (
  <div className="recipe-is-from-widget__content gel-wrap">
    <div className="recipe-is-from-widget__item">
      <h2 className="recipe-is-from-widget__header gel-great-primer">This recipe is from...</h2>

      <div className="recipe-is-from-widget__about">
        <a
          href={`/food/programmes/${programmeTopLevel.id}`}
          className="recipe-is-from-widget__link"
        >
          <Image
            src={programmeSeries.placeholderImageUrl}
            className="recipe-is-from-widget__image gel-1/1"
          />
          <p className="recipe-is-from-widget__programme-series-title gel-pica-bold">
            {programmeTopLevel.title}
          </p>
          {programmeEpisode.title !== '' && (
            <p className="recipe-is-from-widget__programme-episode-title gel-long-primer">
              {programmeEpisode.title}
            </p>
          )}
        </a>
        <div className="recipe-is-from-widget__programme-channel-name-wrapper">
          <p className="recipe-is-from-widget__programme-channel-name gel-brevier">
            {programmeSeries.channelName}
          </p>
        </div>
      </div>
    </div>
    {recipesFromThisEpisode && recipesFromThisEpisode.length > 1 && (
      <div className="recipe-is-from-widget__item recipe-is-from-widget__recipe-links">
        <p className="recipe-is-from-widget__recipe-from-this-episode-title gel-great-primer">
          Recipes from this episode
        </p>

        <ul className="recipe-is-from-widget__programme-episode-list">
          {recipesFromThisEpisode.map(
            episode =>
              episode.title !== title && (
                <li
                  key={`episode-${episode.id}`}
                  className="recipe-is-from-widget__programme-episode-recipe-title gel-long-primer"
                >
                  <a
                    href={`/food/recipes/${episode.id}`}
                    className="recipe-is-from-widget__programme-episode-link"
                  >
                    {episode.title}
                  </a>
                </li>
              )
          )}
        </ul>
      </div>
    )}
  </div>
);

IsFromProgramme.defaultProps = {
  programmeEpisode: undefined,
  programmeSeries: undefined,
  programmeTopLevel: undefined,
  recipesFromThisEpisode: [],
};

IsFromProgramme.propTypes = {
  programmeEpisode: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }),
  programmeSeries: PropTypes.shape({
    channelName: PropTypes.string.isRequired,
    placeholderImageUrl: PropTypes.string.isRequired,
  }),
  programmeTopLevel: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  recipesFromThisEpisode: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired
  ),
  title: PropTypes.string.isRequired,
};

export default IsFromProgramme;
