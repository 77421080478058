import React from 'react';
import PropTypes from 'prop-types';
import { dietsShape, metadataShape } from './shapes';

if (process.env.CLIENT) {
  require('./Metadata.scss'); // eslint-disable-line global-require
}

const isVegetarian = diets => diets.find(({ id }) => id === 'vegetarian');

const Metadata = ({ diets, metadata }) => (
  <div className="gel-layout">
    {metadata.prepTime && (
      <div className="recipe-metadata-wrap gel-layout__item gel-1/2 gel-1/1@l gel-pica">
        <p className="recipe-metadata__heading gel-long-primer">Preparation time</p>
        {metadata.prepTimeMeta ? (
          <p className="recipe-metadata__prep-time" content={metadata.prepTimeMeta}>
            {metadata.prepTime}
          </p>
        ) : (
          <p className="recipe-metadata__prep-time">{metadata.prepTime}</p>
        )}
      </div>
    )}
    {metadata.cookingTime && (
      <div className="recipe-metadata-wrap gel-layout__item gel-1/2 gel-1/1@l gel-pica">
        <p className="recipe-metadata__heading gel-long-primer">Cooking time</p>
        {metadata.cookTimeMeta ? (
          <p className="recipe-metadata__cook-time" content={metadata.cookTimeMeta}>
            {metadata.cookingTime}
          </p>
        ) : (
          <p className="recipe-metadata__cook-time">{metadata.cookingTime}</p>
        )}
      </div>
    )}
    {metadata.serving && (
      <div className="recipe-metadata-wrap gel-layout__item gel-1/2 gel-1/1@l gel-pica">
        <p className="recipe-metadata__heading gel-long-primer">Serves</p>
        <p className="recipe-metadata__serving">{metadata.serving}</p>
      </div>
    )}
    {isVegetarian(diets) && (
      <div className="recipe-metadata-wrap gel-layout__item gel-1/2 gel-1/1@l gel-pica">
        <p className="recipe-metadata__heading gel-long-primer">Dietary</p>
        <div className="recipe-metadata__dietary">
          <a href="/food/diets/vegetarian" className="recipe-metadata__dietary-vegetarian">
            &nbsp;
            <p className="recipe-metadata__dietary-vegetarian-text">Vegetarian</p>
          </a>
        </div>
      </div>
    )}
  </div>
);

Metadata.propTypes = {
  diets: PropTypes.arrayOf(dietsShape),
  metadata: PropTypes.shape(metadataShape),
};

Metadata.defaultProps = {
  diets: [],
  metadata: {},
};

export default Metadata;
