import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import StarRating from './StarRating';

if (process.env.CLIENT) {
  require('./RatingsTooltip.scss'); // eslint-disable-line global-require
}

const RatingsTooltip = ({ submitRating, closeTooltip }) => {
  const [rating, setRating] = useState(0);

  return (
    <div className="recipe-ratings__tooltip">
      <div className="recipe-ratings__tooltip__heading gel-great-primer-bold">Rate this recipe</div>
      <div className="recipe-ratings__tooltip__body">
        <StarRating rating={rating} interactive starClicked={setRating} />
        <button
          className={classNames('recipe-ratings__confirm', 'gel-pica-bold', {
            disabled: rating === 0,
          })}
          disabled={rating === 0}
          onClick={() => submitRating(rating)}
          type="button"
        >
          Rate
        </button>
      </div>
      <button
        aria-label="close"
        className="recipe-ratings__close"
        onClick={closeTooltip}
        type="button"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
          <path d="M32 3.5L28.5 0 16 12.5 3.5 0 0 3.5 12.5 16 0 28.5 3.5 32 16 19.5 28.5 32l3.5-3.5L19.5 16z" />
        </svg>
      </button>
    </div>
  );
};

RatingsTooltip.propTypes = {
  submitRating: PropTypes.func.isRequired,
  closeTooltip: PropTypes.func.isRequired,
};

export default RatingsTooltip;
