import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { stagesShape } from './shapes';

if (process.env.CLIENT) {
  require('./IngredientsList.scss'); // eslint-disable-line global-require
}

const IngredientsList = ({ stages }) => (
  <div className="gel-layout recipe-ingredient-list">
    <div className="gel-layout__item">
      <div className="recipe-ingredients-wrapper">
        <h2 className="recipe-ingredients__heading gel-double-pica">Ingredients</h2>

        {stages.map(({ title, ingredients }) => (
          <Fragment key={title}>
            {title && (
              <h3 className="recipe-ingredients__sub-heading gel-great-primer-bold">{title}</h3>
            )}
            <ul className="recipe-ingredients__list">
              {ingredients.map(({ text }, i) => (
                <li
                  key={`ingredient-${i}`} // eslint-disable-line
                  className="recipe-ingredients__list-item"
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              ))}
            </ul>
          </Fragment>
        ))}
      </div>
    </div>
  </div>
);

IngredientsList.propTypes = {
  stages: PropTypes.arrayOf(stagesShape).isRequired,
};

export default IngredientsList;
