import React, { Component } from 'react';

import classNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import IdctaTooltip from '../generic/idcta/Tooltip';

import { FAVOURITE_RECIPE_STATUS } from './constants';
import { addFavouriteRecipe, getFavouriteRecipe, closeTooltip } from './actions';

if (process.env.CLIENT) {
  require('./AddToFavourites.scss'); // eslint-disable-line global-require
}

const { ADDED, ADDING, ERROR_ADDING, LOADING, NOT_FAVOURITED } = FAVOURITE_RECIPE_STATUS;

const getIconClass = status =>
  classNames('recipe-actions__favourites-icon', {
    'recipe-actions__add-favourites-icon': status === ERROR_ADDING || status === NOT_FAVOURITED,
    'recipe-actions__added-favourites-icon': status === ADDED,
    'recipe-actions__adding-favourites-icon': status === ADDING || status === LOADING,
  });

const getStateText = state => {
  switch (state) {
    case ERROR_ADDING:
    case NOT_FAVOURITED:
      return 'Add to favourites';
    case ADDED:
      return 'Added to favourites';
    case ADDING:
      return 'Adding';
    default:
      return 'Loading';
  }
};

export class AddToFavourites extends Component {
  componentDidMount() {
    this.props.getFavouriteRecipe(this.props.recipeId);
  }

  addToFavourites(recipeId) {
    this.props.addFavouriteRecipe(recipeId);
  }

  render() {
    const { favouriteRecipeStatus, recipeId, shouldShowFavouritesTooltip } = this.props;

    return (
      <div
        className="recipe-actions-wrap gel-layout__item gel-1/1 gel-1/2@m"
        id="add-to-favourites-btn-container"
      >
        <div className="my-favourites-recipes__start-adding recipe-actions-text--button">
          <button
            className="recipe-actions-link__button gel-long-primer"
            disabled={
              favouriteRecipeStatus !== ERROR_ADDING && favouriteRecipeStatus !== NOT_FAVOURITED
            }
            id="add-to-favourites-button"
            name="favouritesLink"
            onClick={() => this.addToFavourites(recipeId)}
            type="button"
          >
            <span name="favouritesLinkIcon" className={getIconClass(favouriteRecipeStatus)} />
            {getStateText(favouriteRecipeStatus)}{' '}
          </button>
          {shouldShowFavouritesTooltip && (
            <IdctaTooltip
              onClose={this.props.closeTooltip}
              subtitle="Want to make this later? Sign in to add it to your favourites."
            />
          )}
        </div>

        {favouriteRecipeStatus === ERROR_ADDING && (
          <p className="my-favourites-recipes__start-adding--error gel-brevier">
            Unfortunately we are unable to add this recipe to your favourites, please try again
            later.
          </p>
        )}
      </div>
    );
  }
}

AddToFavourites.propTypes = {
  addFavouriteRecipe: PropTypes.func.isRequired,
  closeTooltip: PropTypes.func.isRequired,
  favouriteRecipeStatus: PropTypes.oneOf(Object.values(FAVOURITE_RECIPE_STATUS)),
  getFavouriteRecipe: PropTypes.func.isRequired,
  recipeId: PropTypes.string.isRequired,
  shouldShowFavouritesTooltip: PropTypes.bool,
};

AddToFavourites.defaultProps = {
  favouriteRecipeStatus: FAVOURITE_RECIPE_STATUS.LOADING,
  shouldShowFavouritesTooltip: false,
};

export default connect(
  state => ({
    favouriteRecipeStatus: state.favouriteReducer.favouriteRecipeStatus,
    shouldShowFavouritesTooltip: state.favouriteReducer.shouldShowFavouritesTooltip,
  }),
  {
    addFavouriteRecipe,
    getFavouriteRecipe,
    closeTooltip,
  }
)(AddToFavourites);
