import React from 'react';
import PropTypes from 'prop-types';
import { openWindow } from './lib/SocialMediaWindow';
import ShareButton from './ShareButton';

const PinterestIcon = (
  <span className="ec-share-icon__svg ec-share-icon__svg--pinterest">
    <svg focusable="false" viewBox="-3 -3 82 82">
      <path d="M27.5 71c3.3 1 6.7 1.6 10.3 1.6C57 72.6 72.6 57 72.6 37.8 72.6 18.6 57 3 37.8 3 18.6 3 3 18.6 3 37.8c0 14.8 9.3 27.5 22.4 32.5-.3-2.7-.6-7.2 0-10.3l4-17.2s-1-2-1-5.2c0-4.8 3-8.4 6.4-8.4 3 0 4.4 2.2 4.4 5 0 3-2 7.3-3 11.4C35.6 49 38 52 41.5 52c6.2 0 11-6.6 11-16 0-8.3-6-14-14.6-14-9.8 0-15.6 7.3-15.6 15 0 3 1 6 2.6 8 .3.2.3.5.2 1l-1 3.8c0 .6-.4.8-1 .4-4.4-2-7-8.3-7-13.4 0-11 7.8-21 22.8-21 12 0 21.3 8.6 21.3 20 0 12-7.4 21.6-18 21.6-3.4 0-6.7-1.8-7.8-4L32 61.7c-.8 3-3 7-4.5 9.4z" />
    </svg>
  </span>
);

const pinterestURL = 'https://www.pinterest.com/pin/create/button';

const Pinterest = ({ link, media, onClickCallback }) => (
  <ShareButton
    icon={PinterestIcon}
    name="Pinterest"
    onClick={() => {
      if (onClickCallback) onClickCallback();

      openWindow(
        `${pinterestURL}?url=${link}${media ? `&media=${encodeURIComponent(media)}` : ''}`
      );
    }}
  />
);

Pinterest.defaultProps = {
  media: undefined,
  onClickCallback: undefined,
};

Pinterest.propTypes = {
  link: PropTypes.string.isRequired,
  media: PropTypes.string,
  onClickCallback: PropTypes.func,
};

export default Pinterest;
