import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Paragraphs from '../generic/Paragraphs';

if (process.env.CLIENT) {
  require('./ExtraInformation.scss'); // eslint-disable-line global-require
}

const ExtraInformation = ({ id, children, icon, text, title }) => (
  <div id={id} className={classNames('recipe-extra-information__wrapper gel-wrap')}>
    <div className="recipe-extra-information__item gel-layout">
      <div className="gel-layout__item gel-1/1">
        <span
          className={classNames(
            'recipe-extra-information__icon',
            `recipe-extra-information__icon--${icon}`
          )}
        />
        <h3 className="recipe-extra-information__title gel-great-primer-bold">{title}</h3>
        {text && <Paragraphs text={text} className="recipe-extra-information__text gel-pica" />}
        {children}
      </div>
    </div>
  </div>
);

ExtraInformation.defaultProps = {
  children: undefined,
  text: undefined,
};

ExtraInformation.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default ExtraInformation;
