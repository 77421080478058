import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { recipeShape } from './shapes';

if (process.env.CLIENT) {
  require('./Title.scss'); // eslint-disable-line global-require
}

const getContentTitleClass = ({ chefDetails, description, hasImage, video }) =>
  chefDetails === '' && description === '' && !hasImage && !video
    ? 'recipe-title--large-spacing'
    : 'recipe-title--small-spacing';

const RecipeTitle = ({ recipe }) => (
  <div className="gel-layout">
    <div
      className={classNames(
        'gel-layout__item gel-1/1 gel-2/3@m gel-3/4@l',
        getContentTitleClass(recipe)
      )}
    >
      <h1 className="gel-trafalgar content-title__text">{recipe.title}</h1>
    </div>
  </div>
);

RecipeTitle.propTypes = {
  recipe: PropTypes.shape(recipeShape).isRequired,
};

export default RecipeTitle;
