import React from 'react';

if (process.env.CLIENT) {
  require('./SocialPromo.scss'); // eslint-disable-line global-require
}

const SocialPromo = () => (
  <div className="social-promo-wrapper gel-layout">
    <div className="social-promo-twitter__wrapper gel-layout__item gel-1/1 gel-1/3@m">
      <a
        href="http://www.twitter.com/BBCFood"
        className="social-promo-twitter-link gel-long-primer-bold"
      >
        <span className="social-promo-twitter-icon" />
        <div className="social-promo-link-text">
          Follow <div className="social-promo-site-name">BBC Food</div>
        </div>
      </a>
    </div>

    <div className="social-promo-facebook__wrapper gel-layout__item gel-1/1 gel-1/3@m">
      <a
        href="http://www.facebook.com/bbcfood"
        className="social-promo-facebook-link gel-long-primer-bold"
      >
        <span className="social-promo-facebook-icon" />
        <div className="social-promo-link-text">
          Like <div className="social-promo-site-name">BBC Food</div>
        </div>
      </a>
    </div>
    <div className="social-promo-pinterest__wrapper gel-layout__item gel-1/1 gel-1/3@m">
      <a
        href="http://www.pinterest.com/bbcfood"
        className="social-promo-pinterest-link gel-long-primer-bold"
      >
        <span className="social-promo-pinterest-icon" />
        <div className="social-promo-link-text">
          Follow <div className="social-promo-site-name">BBC Food</div>
        </div>
      </a>
    </div>
  </div>
);

export default SocialPromo;
