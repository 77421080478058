import {
  HIDE_RATINGS_TOOLTIP,
  TOGGLE_RATINGS_TOOLTIP,
  TOGGLE_RATINGS_SIGNIN_TOOLTIP,
  RATING_LOADED,
  RATING_STATUS,
  SET_RATING,
} from './constants';
import { hasValidToken, isAvailable } from '../../generic/idcta/actions';

export const toggleRatings = () => async dispatch => {
  if (await hasValidToken()) {
    dispatch({
      type: TOGGLE_RATINGS_TOOLTIP,
    });
  } else {
    dispatch({
      type: TOGGLE_RATINGS_SIGNIN_TOOLTIP,
    });
  }
};

export const hideRatings = () => ({
  type: HIDE_RATINGS_TOOLTIP,
});

export const rate = (recipeId, rating) => async (dispatch, getState) => {
  dispatch({
    type: RATING_LOADED,
    status: RATING_STATUS.PENDING,
  });

  try {
    await isAvailable();

    if (await hasValidToken()) {
      const { uas } = getState().pageReducer;

      const res = await fetch(`${uas.url}my/ratings`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-API-Key': uas.apiKey,
          'X-Authentication-Provider': 'idv5',
        },
        credentials: 'include',
        body: JSON.stringify({
          resourceDomain: 'food',
          resourceType: 'recipe',
          resourceId: `urn:bbc:food::recipe:${recipeId}`,
          action: 'rated',
          actionContext: parseInt(rating * 2, 10),
        }),
      });

      switch (res.status) {
        case 202:
          dispatch({
            type: RATING_LOADED,
            status: RATING_STATUS.RATED,
          });
          break;
        default:
          dispatch({
            type: RATING_LOADED,
            status: RATING_STATUS.FAILED,
          });
      }
    } else {
      dispatch({
        type: RATING_LOADED,
        status: RATING_STATUS.FAILED,
      });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`[Ratings] Failed to rate recipe`, err);
    dispatch({
      type: RATING_LOADED,
      status: RATING_STATUS.FAILED,
    });
  }
};

const getUserRating = async (recipeId, uas) => {
  if (!process.env.CLIENT) Promise.resolve();

  try {
    await isAvailable();

    if (await hasValidToken()) {
      const res = await fetch(`${uas.url}my/ratings/urn:bbc:food:recipe:food::recipe:${recipeId}`, {
        headers: {
          Accept: 'application/json',
          'X-API-Key': uas.apiKey,
          'X-Authentication-Provider': 'idv5',
        },
        credentials: 'include',
      });

      switch (res.status) {
        case 200:
          return RATING_STATUS.RATED;
        case 204:
          return RATING_STATUS.UNRATED;
        case 401:
          return RATING_STATUS.UNRATED;
        default:
          return RATING_STATUS.ERROR;
      }
    } else {
      return RATING_STATUS.UNRATED;
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`[Ratings] Failed to add rating to recipe`, err);
    return RATING_STATUS.ERROR;
  }
};

const getAggregateRating = async recipeId => {
  const res = await fetch(`/food/ratings/recipe/${recipeId}`);
  return res.json();
};

export const getRatings = recipeId => async (dispatch, getState) => {
  const { uas } = getState().pageReducer;

  const [status, rating] = await Promise.all([
    getUserRating(recipeId, uas),
    getAggregateRating(recipeId),
  ]);

  dispatch({
    type: SET_RATING,
    rating,
  });
  dispatch({
    type: RATING_LOADED,
    status,
  });
};
