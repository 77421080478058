import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Image from '../generic/Image';

if (process.env.CLIENT) {
  require('./RelatedRecipes.scss'); // eslint-disable-line global-require
}

const getRelationHref = recipe => {
  if (recipe.relationType === 'collection') {
    return recipe.relationIsMenu
      ? `/food/menus/${recipe.relationId}`
      : `/food/collections/${recipe.relationId}`;
  }
  return `/food/${recipe.relationId}`;
};

const Related = ({ relatedRecipes }) => (
  <div className="related-recipes__content gel-wrap">
    {relatedRecipes.map((recipe, i) => (
      <div
        key={`related-${recipe.recipeId}`}
        className={classNames(
          'related-recipes__item',
          i === 0 ? 'related-recipes__item--primary' : 'related-recipes__item--secondary',
          { 'related-recipes__item--last': i === relatedRecipes.length - 1 }
        )}
      >
        {i === 0 && <h2 className="related-recipes__header gel-great-primer">Related Recipes</h2>}

        <div className={`related-recipes__about related-recipes__about--${recipe.relationType}`}>
          {recipe.hasImage && (
            <a href={`/food/recipes/${recipe.recipeId}`} className="related-recipes__image-link">
              <div
                className={classNames(
                  'related-recipes__image-container',
                  i === 0 ? 'gel-1/1' : 'gel-1/2 gel-1/1@m gel-1/2@l'
                )}
              >
                <Image
                  className="related-recipes__image gel-1/1"
                  src={`food/ic/food_16x9_{width}/recipes/${recipe.recipeId}_16x9.jpg`}
                />
              </div>
            </a>
          )}
          <div
            className={classNames(
              'related-recipes__information',
              i !== 0 && (recipe.hasImage ? 'gel-1/2 gel-1/1@m gel-1/2@l' : 'gel-1/1')
            )}
          >
            <a href={`/food/recipes/${recipe.recipeId}`} className="related-recipes__title-link">
              <h3 className="related-recipes__title gel-pica-bold">{recipe.recipeTitle}</h3>
            </a>
            <p className="related-recipes__author gel-long-primer">
              <strong>By</strong>
              &nbsp;
              <span className="related-recipes__author-name">{recipe.chef}</span>
            </p>
          </div>
        </div>

        <p className="related-recipes__more gel-long-primer">
          <strong>See more</strong>
          &nbsp;
          <a href={getRelationHref(recipe)} className="related-recipes__more-link">
            <span className="related-recipes__more-name">{recipe.relationTitle}</span>
            {!recipe.relationTitle.includes('recipes') && ' recipes'}
            &nbsp;
            <span className="related-recipes__more-amount">{`(${recipe.amount})`}</span>
          </a>
        </p>
      </div>
    ))}
  </div>
);

Related.propTypes = {
  relatedRecipes: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number.isRequired,
      chef: PropTypes.string.isRequired,
      hasImage: PropTypes.bool.isRequired,
      recipeId: PropTypes.string.isRequired,
      recipeTitle: PropTypes.string.isRequired,
      relationId: PropTypes.string.isRequired,
      relationTitle: PropTypes.string.isRequired,
      relationType: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default Related;
