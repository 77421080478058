import React from 'react';
import PropTypes from 'prop-types';

const ShareButton = ({ onClick, icon, name }) => (
  <button
    className={`ec-share-button ec-share-button--transparent ec-share-button--${name.toLowerCase()}`}
    type="button"
    onClick={onClick}
  >
    {icon}
    <span className="ec-share-cta-text">{name}</span>
  </button>
);

ShareButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
};

export default ShareButton;
