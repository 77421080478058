import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CopyLinkBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
    };
  }

  handleCopyUrl() {
    try {
      this.inputElement.select();
      document.execCommand('copy');
      this.setState({
        copied: true,
      });
    } catch (err) {
      // eslint-disable-line
    }
  }

  render() {
    return (
      <div className="ec-share-copy-link">
        <label className="ec-share-copy-link__text">
          {this.state.copied ? 'Copied!' : this.props.shareText}
        </label>
        <input
          className="ec-share-copy-link__input"
          onClick={() => {
            if (this.props.onClickCallback) this.props.onClickCallback();
            this.handleCopyUrl();
          }}
          readOnly
          ref={ele => {
            this.inputElement = ele;
          }}
          type="text"
          value={this.props.link}
        />
      </div>
    );
  }
}

CopyLinkBox.defaultProps = {
  shareText: 'Copy and share link',
  onClickCallback: undefined,
};

CopyLinkBox.propTypes = {
  link: PropTypes.string.isRequired,
  shareText: PropTypes.string,
  onClickCallback: PropTypes.func,
};

export default CopyLinkBox;
