import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { NextIcon } from '../Icons';

if (process.env.CLIENT) {
  require('./SeasonalPromoBlock.scss'); // eslint-disable-line global-require
}

const SeasonalPromoBlock = ({
  season: {
    title,
    subtitle,
    url,
    id,
    styles: { titleColor, subtitleColor },
  },
  foodImagesPath,
}) => (
  <a
    className={classnames('seasonal-promo-block')}
    href={`/food${url}`}
    style={{
      backgroundImage: `url(${foodImagesPath}/food/timed_promos/${id}.jpg)`,
    }}
  >
    <div>
      <p
        className={classnames('seasonal-promo-block__title', 'gel-double-pica-bold')}
        style={{ color: titleColor }}
      >
        {title}
      </p>
      <p
        className={classnames('gel-double-pica', 'seasonal-promo-block__recipes')}
        style={{ color: subtitleColor }}
      >
        {subtitle || 'Recipes'} <NextIcon />
      </p>
    </div>
  </a>
);

SeasonalPromoBlock.propTypes = {
  season: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    styles: PropTypes.shape({
      titleColor: PropTypes.string.isRequired,
      subtitleColor: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  foodImagesPath: PropTypes.string.isRequired,
};

export default SeasonalPromoBlock;
