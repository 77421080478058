import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {
  SharePanel,
  Facebook,
  Twitter,
  WhatsApp,
  Pinterest,
  Yummly,
  CopyLinkBox,
} from '../generic/sharetool';

if (process.env.CLIENT) {
  require('./ShareComponent.scss'); // eslint-disable-line global-require
}

export const ShareComponent = ({ ichefBasePath, recipeId }) => {
  const [clientUrl, setClientUrl] = useState('');

  useEffect(() => {
    setClientUrl(window.location.href);
  }, []);

  return (
    <div
      className="recipe-actions-wrap gel-layout__item gel-1/1 gel-1/2@m"
      id="share-component-btn-container"
    >
      <SharePanel>
        <Facebook link={clientUrl} />
        <Twitter link={clientUrl} />
        <Pinterest
          link={clientUrl}
          media={`${ichefBasePath}food/ic/food_16x9_448/recipes/${recipeId}_16x9.jpg`}
        />
        <WhatsApp link={clientUrl} />
        <Yummly
          link={clientUrl}
          image={`${ichefBasePath}food/ic/food_16x9_448/recipes/${recipeId}_16x9.jpg`}
        />
        <CopyLinkBox link={clientUrl} shareText="Copy link" />
      </SharePanel>
    </div>
  );
};

ShareComponent.propTypes = {
  ichefBasePath: PropTypes.string.isRequired,
  recipeId: PropTypes.string.isRequired,
};

export default connect(state => ({
  ichefBasePath: state.pageReducer.ichefBasePath,
  recipeId: get(state, ['recipeReducer', 'recipe', 'id']),
}))(ShareComponent);
