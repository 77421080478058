import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import CloseButton from './CloseButton';
import DefaultShareButton from './DefaultShareButton';

const toArray = thing => [].concat(thing);

class SharePanel extends Component {
  constructor(props) {
    super(props);
    this.closeSharePanel = this.closeSharePanel.bind(this);
    this.handleFocusOut = this.handleFocusOut.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.closeSharePanel);
    document.addEventListener('touchstart', this.closeSharePanel);
    document.addEventListener('focusout', this.handleFocusOut);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.closeSharePanel);
    document.removeEventListener('touchstart', this.closeSharePanel);
    document.removeEventListener('focusout', this.handleFocusOut);
  }

  handleFocusOut(ev) {
    // when tabbing out of SharePanel, ev.relatedTarget is the element getting focus,
    // but when clicking inside SharePanel, ev.relatedTarget is null, which we need to check if the clicking
    // happened inside SharePanel.
    this.closeSharePanel({ target: ev.relatedTarget || ev.target });
  }

  toggleSharePanel(e) {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
    e.stopPropagation();
    e.preventDefault();
  }

  closeSharePanel(ev) {
    if (this.state.isOpen && !this.sharePanel.contains(ev.target)) {
      this.setState({ isOpen: false });
    }
  }

  render() {
    const {
      className,
      children,
      ctaText,
      headerText,
      readMoreText,
      shareButtonComponent: ShareButton,
      onClickCallback,
    } = this.props;

    return (
      <div
        className={classnames('ec-share-tools', className)}
        ref={sharePanel => {
          this.sharePanel = sharePanel;
        }}
      >
        <ShareButton
          className="ec-share-tools__open-button"
          active={this.state.isOpen}
          onButtonClick={e => {
            if (onClickCallback) onClickCallback();
            this.toggleSharePanel(e);
          }}
          ctaText={ctaText}
        />

        <div
          className={classnames({
            'ec-share-panel': true,
            'ec-share-panel--open': this.state.isOpen,
          })}
        >
          <div className="ec-share-panel__header">
            <span className="ec-share-panel__header-text">{headerText}</span>
            <CloseButton onClick={e => this.toggleSharePanel(e)} />
          </div>
          <div className="ec-share-panel__body">
            <ul className="ec-share-panel__items">
              {toArray(children).map((item, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={i} className="ec-share-panel__item">
                  {item}
                </li>
              ))}
              <li className="ec-share-panel__item">
                <a
                  className="ec-share-panel__read-more-link"
                  href="http://www.bbc.co.uk/faqs/questions/bbc_online/sharing"
                >
                  {readMoreText}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

SharePanel.defaultProps = {
  children: [],
  className: undefined,
  ctaText: 'Share',
  headerText: 'Share this with',
  readMoreText: 'Read more about sharing.',
  shareButtonComponent: DefaultShareButton,
  onClickCallback: undefined,
};

SharePanel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  ctaText: PropTypes.string,
  headerText: PropTypes.string,
  readMoreText: PropTypes.string,
  shareButtonComponent: PropTypes.func,
  onClickCallback: PropTypes.func,
};

export default SharePanel;
