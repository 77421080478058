const defaultWidth = 575;
const defaultHeight = 400;

const getCenter = (outer, inner) => {
  const result = (outer - inner) / 2;
  return result > 0 ? Math.floor(result) : 0;
};

export const openWindow = (url, windowId) => {
  if (!window) return;

  const windowWidth =
    window.innerWidth ||
    window.document.documentElement.clientWidth ||
    window.document.body.clientWidth;

  const windowHeight =
    window.innerHeight ||
    window.document.documentElement.clientHeight ||
    window.document.body.clientHeight;

  const width = defaultWidth;
  const height = defaultHeight;
  const left = getCenter(windowWidth, defaultWidth);
  const top = getCenter(windowHeight, defaultHeight);
  const opts = `status=1,width=${width},height=${height},left=${left},top=${top}`;

  const childWindow = window.open(url, windowId, opts);
  childWindow.moveTo(left, top);
};
