import React from 'react';
import PropTypes from 'prop-types';
import { openWindow } from './lib/SocialMediaWindow';

import ShareButton from './ShareButton';

const YummlyIcon = (
  <span className="ec-share-icon__svg ec-share-icon__svg--yummly">
    <svg focusable="false" viewBox="-3 0 29 29">
      <path
        d="m23.55,22c0,-0.2 -0.2,-0.3 -0.3,-0.3c-0.3,-0.1 -0.5,0 -1.3,-0.4c-0.6,-0.3 -3.5,-1.7 -7.5,-2.1l2.9,-16.4c0.1,-0.7 0.1,-1.3 -0.1,-1.7c-0.3,-0.6 -1.1,-0.7 -1.9,-0.6c-0.7,0.1 -1.2,0.3 -1.3,0.4s-0.2,0.2 -0.2,0.4c0,0.3 0.3,0.6 0.1,1.7c0,0.3 -1,5.5 -1.8,10c-2.1,1.3 -4.9,1.9 -5.5,1.1c-0.3,-0.4 -0.2,-1.1 0.1,-2.2c0.1,-0.2 1.3,-4.9 1.6,-6.3c0.7,-2.6 0.2,-4.9 -2.5,-5.2c-2.3,-0.2 -4.5,1.1 -5.2,1.9c-0.5,0.5 -0.3,1.1 0.1,1.9c0.3,0.6 0.8,1 0.9,1c0.1,0.1 0.3,0.1 0.4,0c0.8,-0.9 2.2,-1.5 2.7,-1.1c0.4,0.4 0.3,1 0.1,1.7c0,0 -1.5,5.6 -2.1,8c-0.4,1.7 0,3.3 1.3,4.1c0.9,0.6 2.3,0.5 3.4,0.4c2.4,-0.3 3.8,-1.3 4,-1.5c-0.3,1.5 -0.4,2.5 -0.4,2.5s-2.7,0.2 -4.9,1.6c-2.9,1.7 -4.1,5.7 -2.2,7.8c1.9,2.1 5.1,1.3 6.5,0.4c1.3,-0.9 2.9,-2.7 3.7,-6.8c4.4,0.2 5.5,2.5 7.4,2.6c1.2,-0.2 2.1,-1.5 2,-2.9zm-15,4c-0.6,0.4 -1.3,0.4 -1.7,0c-0.4,-0.5 -0.5,-3.1 3.6,-3.8c0,-0.1 -0.7,3 -1.9,3.8z"
        id="svg_8"
      />
    </svg>
  </span>
);

const yummlyURL = 'http://www.yummly.com/urb/verify';

const Yummly = ({ link, title, image, onClickCallback }) => (
  <ShareButton
    icon={YummlyIcon}
    name="Yummly"
    onClick={() => {
      if (onClickCallback) onClickCallback();

      let url = `${yummlyURL}?url=${encodeURIComponent(link)}`;
      if (title) url += `&title=${encodeURIComponent(`${title}`)}`;
      if (image) url += `&image=${encodeURIComponent(`${image}`)}`;
      openWindow(url);
    }}
  />
);

Yummly.defaultProps = {
  title: undefined,
  image: undefined,
  onClickCallback: undefined,
};

Yummly.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string,
  image: PropTypes.string,
  onClickCallback: PropTypes.func,
};

export default Yummly;
