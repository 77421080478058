/* eslint-disable react/no-unknown-property */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getIdctaInit } from './actions';

if (process.env.CLIENT) {
  require('./Tooltip.scss'); // eslint-disable-line global-require
}

export class Tooltip extends Component {
  tooltip = React.createRef();

  signInLink;

  registerLink;

  componentDidMount() {
    document.addEventListener('mousedown', this.onClose);
    this.props.getIdctaInit(this.props.idctaPath);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.idctaInit !== this.props.idctaInit) {
      [this.signInLink] = this.tooltip.current.getElementsByClassName('id4-cta-signin');
      if (this.signInLink) {
        this.signInLink.addEventListener('click', this.onSignIn);
      }

      [this.registerLink] = this.tooltip.current.getElementsByClassName('id4-cta-register');
      if (this.registerLink) {
        this.registerLink.addEventListener('click', this.onRegister);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onClose);

    if (this.signInLink) {
      this.signInLink.removeEventListener('click', this.onSignIn);
    }

    if (this.registerLink) {
      this.registerLink.removeEventListener('click', this.onRegister);
    }
  }

  onSignIn = event => {
    this.props.onSignIn(event);
  };

  onRegister = event => {
    this.props.onRegister(event);
  };

  onClose = event => {
    if (this.tooltip.current && !this.tooltip.current.contains(event.target)) {
      this.props.onClose();
    }
  };

  render() {
    const { idctaInit, idctaFlagpoleRed } = this.props;

    if (!idctaInit) return '';

    return (
      <div ref={this.tooltip} className={classNames('cta-tooltip__wrapper')}>
        <div className="cta-tooltip" id="cta-tooltip">
          <span className="cta-tooltip__pointer" />
          <div className="cta-tooltip__inner">
            {!idctaInit.error && !idctaFlagpoleRed ? (
              <div className="cta-tooltip__content cta-tooltip__content--success">
                <p className="cta-tooltip__subtitle gel-pica">{this.props.subtitle}</p>
                <div
                  className="cta-tooltip__id"
                  dangerouslySetInnerHTML={{ __html: idctaInit.html }}
                />
              </div>
            ) : (
              <div className="cta-tooltip__content cta-tooltip__content--error">
                <p className="cta-tooltip__subtitle">{this.props.error}</p>
              </div>
            )}
            <button
              className="cta-tooltip__close"
              title="Close"
              aria-label="Close Tooltip"
              onClick={this.props.onClose}
              type="button"
            >
              <span className="cta-tooltip__close-icon">
                <i className="cta-tooltip__close-gel-icon" />
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

Tooltip.propTypes = {
  onSignIn: PropTypes.func,
  onRegister: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  getIdctaInit: PropTypes.func,
  idctaInit: PropTypes.shape({
    error: PropTypes.bool,
    html: PropTypes.string,
  }),
  idctaFlagpoleRed: PropTypes.bool,
  idctaPath: PropTypes.string.isRequired,
  error: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
};

Tooltip.defaultProps = {
  onSignIn: () => {},
  onRegister: () => {},
  idctaFlagpoleRed: false,
  getIdctaInit,
  idctaInit: undefined,
  error: 'Sorry, there has been a problem. Please try again later.',
};

export default connect(
  state => ({
    idctaInit: state.idctaReducer.idctaInit,
    idctaPath: state.pageReducer.idctaPath,
    idctaFlagpoleRed: state.idctaReducer.idctaFlagpoleRed,
  }),
  {
    getIdctaInit,
  }
)(Tooltip);
