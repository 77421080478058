import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { StarIconRated, StarIconUnrated } from './RatingIcons';

if (process.env.CLIENT) {
  require('./StarRating.scss'); // eslint-disable-line global-require
}

class StarRating extends Component {
  state = {
    rating: null,
    clicked: false,
  };

  onHover = rating => {
    this.setState({ rating });
  };

  onHoverOut = () => {
    this.setState({ rating: null });
  };

  starClicked = rating => {
    this.props.starClicked(rating);

    this.setState({
      clicked: false,
      rating: null,
    });

    setTimeout(() => this.setState({ clicked: true }), 0);
  };

  render() {
    const iOS = navigator.userAgent.match(/iPad|iPhone/);

    return (
      <div className={classNames('star-rating', { clicked: this.state.clicked })}>
        {[...Array(5).keys()].map(index => {
          const ratingIndex = index + 1;
          const filled = (this.state.rating || this.props.rating) >= ratingIndex;
          const selected = this.props.rating >= ratingIndex;
          const plural = ratingIndex > 1 ? 's' : '';
          return (
            <button
              key={ratingIndex}
              aria-label={`rate ${ratingIndex} star${plural}`}
              className={classNames('star-rating__star', {
                'star-rating__star--selected': selected,
              })}
              onClick={() => this.starClicked(ratingIndex)}
              onTouchEnd={() => this.starClicked(ratingIndex)}
              onMouseEnter={!iOS && (() => this.onHover(ratingIndex))}
              onMouseLeave={!iOS && this.onHoverOut}
              type="button"
            >
              {filled ? <StarIconRated /> : <StarIconUnrated />}
            </button>
          );
        })}
      </div>
    );
  }
}

StarRating.propTypes = {
  rating: PropTypes.number,
  starClicked: PropTypes.func.isRequired,
};

StarRating.defaultProps = {
  rating: 0,
};

export { StarRating as default, StarIconRated, StarIconUnrated };
