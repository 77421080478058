import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const ShareIcon = ({ children }) => (
  <>
    <span className="ec-share-icon__svg">
      <svg
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 32 32"
      >
        <path d="M6.8 22.2c1.6 0 3-.7 4-1.8l9 4.8c-.1.4-.2.8-.2 1.3 0 3 2.5 5.5 5.5 5.5s5.5-2.4 5.5-5.5-2.5-5.5-5.5-5.5c-1.6 0-3 .7-4 1.8l-9.5-5.2-.1-2.8 9.7-5.5c1 1 2.3 1.5 3.8 1.5 3 0 5.5-2.5 5.5-5.5S28.2 0 25.2 0s-5.5 2.4-5.5 5.5c0 .6.1 1.1.2 1.6l-9.3 5.7c-1-.9-2.3-1.5-3.8-1.5-3 0-5.5 2.4-5.5 5.5.1 2.9 2.5 5.4 5.5 5.4z" />
      </svg>
    </span>
    <span className="ec-share-cta-text">{children}</span>
  </>
);

ShareIcon.propTypes = {
  children: PropTypes.node.isRequired,
};

const DefaultShareButton = ({ active, ctaText, onButtonClick }) => (
  <button
    className={classNames({
      'ec-share-button': true,
      'ec-share-button__share': true,
      'ec-share-button__share--open': active,
    })}
    type="button"
    onClick={onButtonClick}
  >
    <ShareIcon>{ctaText}</ShareIcon>
  </button>
);

DefaultShareButton.defaultProps = {
  active: false,
};

DefaultShareButton.propTypes = {
  active: PropTypes.bool,
  ctaText: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
};

export default DefaultShareButton;
