import React from 'react';
import PropTypes from 'prop-types';
import { howToVideoShape } from './shapes';

if (process.env.CLIENT) {
  require('./QuickLinks.scss'); // eslint-disable-line global-require
}

const QuickLinks = ({ tips, howToVideos, wines }) => (
  <div className="gel-layout">
    <div className="recipe-quick-links gel-layout__item gel-1/1">
      <div className="recipe-quick-links-wrapper">
        {tips && (
          <a
            href="#recipe-tips"
            className="recipe-quick-link recipe-quick-link--tips gel-long-primer"
          >
            <span className="recipe-quick-link__icon" />
            <span className="recipe-quick-link__text">Recipe tips</span>
          </a>
        )}

        {howToVideos.length > 0 && (
          <a
            href="#recipe-how-to-videos"
            className="recipe-quick-link recipe-quick-link--how-to-videos gel-long-primer"
          >
            <span className="recipe-quick-link__icon" />
            <span className="recipe-quick-link__text">How-to-videos</span>
          </a>
        )}

        {wines && (
          <a
            href="#recipe-wine"
            className="recipe-quick-link recipe-quick-link--wine-recommendation gel-long-primer"
          >
            <span className="recipe-quick-link__icon" />
            <span className="recipe-quick-link__text">Recommended wine</span>
          </a>
        )}
      </div>
    </div>
  </div>
);

QuickLinks.propTypes = {
  tips: PropTypes.string,
  wines: PropTypes.string,
  howToVideos: PropTypes.arrayOf(PropTypes.shape(howToVideoShape)).isRequired,
};

QuickLinks.defaultProps = {
  tips: '',
  wines: '',
};

export default QuickLinks;
