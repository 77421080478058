import React from 'react';
import PropTypes from 'prop-types';

const CloseButton = ({ onClick }) => (
  <button className="ec-share-panel__close-button" type="button" onClick={onClick}>
    <span className="ec-share-panel__close-button-icon">
      <svg focusable="false" viewBox="0 0 32 32" width="16" height="16">
        <path d="M32 3.5L28.5 0 16 12.5 3.5 0 0 3.5 12.5 16 0 28.5 3.5 32 16 19.5 28.5 32l3.5-3.5L19.5 16" />
      </svg>
    </span>
  </button>
);

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CloseButton;
