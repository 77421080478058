import React from 'react';
import PropTypes from 'prop-types';
import { openWindow } from './lib/SocialMediaWindow';
import ShareButton from './ShareButton';

const TwitterIcon = (
  <span className="ec-share-icon__svg ec-share-icon__svg--twitter">
    <svg focusable="false" viewBox="0 0 32 32">
      <path d="M32 6.1c-1.2.5-2.4.9-3.8 1 1.4-.8 2.4-2.1 2.9-3.6-1.3.8-2.7 1.3-4.2 1.6C25.7 3.8 24 3 22.2 3c-3.6 0-6.6 2.9-6.6 6.6 0 .5.1 1 .2 1.5-5.5-.3-10.3-2.9-13.6-6.9-.6 1-.9 2.1-.9 3.3 0 2.3 1.2 4.3 2.9 5.5-1.1 0-2.1-.3-3-.8v.1c0 3.2 2.3 5.8 5.3 6.4-.6.1-1.1.2-1.7.2-.4 0-.8 0-1.2-.1.8 2.6 3.3 4.5 6.1 4.6-2.2 1.8-5.1 2.8-8.2 2.8-.5 0-1.1 0-1.6-.1 3 1.8 6.5 2.9 10.2 2.9 12.1 0 18.7-10 18.7-18.7v-.9c1.2-.9 2.3-2 3.2-3.3z" />
    </svg>
  </span>
);

const twitterURL = 'https://twitter.com/intent/tweet';

const Twitter = ({ text, hashTags, link, via, onClickCallback }) => (
  <ShareButton
    icon={TwitterIcon}
    name="Twitter"
    onClick={() => {
      if (onClickCallback) onClickCallback();

      let url = `${twitterURL}?url=${link}`;
      if (text) url += `&text=${encodeURIComponent(text)}`;
      if (hashTags) url += `&hashtags=${hashTags.join(',')}`;
      if (via) url += `&via=${via}`;
      openWindow(url);
    }}
  />
);

Twitter.defaultProps = {
  text: undefined,
  hashTags: undefined,
  via: undefined,
  onClickCallback: undefined,
};

Twitter.propTypes = {
  text: PropTypes.string,
  hashTags: PropTypes.arrayOf(PropTypes.string),
  link: PropTypes.string.isRequired,
  via: PropTypes.string,
  onClickCallback: PropTypes.func,
};

export default Twitter;
