import React from 'react';
import PropTypes from 'prop-types';
import { StarIconRated, StarIconUnrated, StarIconHalf } from './RatingIcons';

if (process.env.CLIENT) {
  require('./AggregateRating.scss'); // eslint-disable-line global-require
}

const asText = rating =>
  rating % 1 === 0 ? `${rating} of 5 stars` : `${Math.floor(rating)} and a half of 5 stars`;

const AggregateRating = ({ rating, total }) => (
  <div className="aggregate-rating">
    <span aria-label={asText(rating)}>
      {[...Array(5).keys()].map(index => {
        let icon;

        if (rating >= index + 1) {
          icon = <StarIconRated />;
        } else if (rating === index + 0.5) {
          icon = <StarIconHalf />;
        } else {
          icon = <StarIconUnrated />;
        }

        return (
          <span key={index} className="aggregate-rating__star">
            {icon}
          </span>
        );
      })}
    </span>
    <span className="aggregate-rating__total gel-long-primer-bold">
      {`${total} ${total > 1 ? 'ratings' : 'rating'}`}
    </span>
  </div>
);

AggregateRating.propTypes = {
  rating: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default AggregateRating;
