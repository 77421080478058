import React from 'react';
import PropTypes from 'prop-types';

import Video from '../generic/Video';

if (process.env.CLIENT) {
  require('./HowToVideo.scss'); // eslint-disable-line global-require
}

const HowToVideo = ({ howToVideos }) => (
  <div className="recipe-how-to-videos gel-layout">
    {howToVideos.map(video => (
      <div className="gel-layout__item gel-1/1 gel-1/2@m" key={video.versionPid}>
        <Video {...video} />
        <a
          className="recipe-how-to-videos__link gel-pica-bold"
          href={`/food/techniques/${video.id}`}
        >
          {video.title}
        </a>
      </div>
    ))}
  </div>
);

HowToVideo.propTypes = {
  howToVideos: PropTypes.arrayOf(
    PropTypes.shape({
      programmeClip: PropTypes.string,
      vpid: PropTypes.string,
      id: PropTypes.string,
      placeholderImageUrl: PropTypes.string,
    })
  ).isRequired,
};

export default HowToVideo;
