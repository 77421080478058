import React from 'react';
import PropTypes from 'prop-types';

import { recipeShape } from './shapes';

if (process.env.CLIENT) {
  require('./SearchLink.scss'); // eslint-disable-line global-require
}

const SearchLink = ({ isAMP, recipe }) => (
  <>
    <div className="gel-2/3@m" />
    <div className="gel-layout__item gel-1/3@m recipe-finder-link">
      <div className="recipe-search-quick-link__wrapper">
        <div className="recipe-search-quick-link">
          <a
            className="recipe-search-quick-link__link"
            href={
              isAMP ? `/food/search?q=${encodeURIComponent(recipe.title)}` : '#recipe-finder-small'
            }
          >
            <span className="gel-long-primer recipe-search-quick-link__text">
              {isAMP ? 'Search for more recipes' : 'Quick recipe finder'}
            </span>
            <span className="recipe-search-quick-link__icon" />
          </a>
        </div>
      </div>
    </div>
  </>
);

SearchLink.defaultProps = {
  isAMP: false,
  recipe: {},
};

SearchLink.propTypes = {
  isAMP: PropTypes.bool,
  recipe: PropTypes.shape(recipeShape),
};

export default SearchLink;
