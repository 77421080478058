import React from 'react';
import PropTypes from 'prop-types';
import { StarIconUnrated } from './StarRating';
import { RATING_STATUS } from './constants';

if (process.env.CLIENT) {
  require('./RateRecipeCta.scss'); // eslint-disable-line global-require
}

const RateRecipeSpinner = ({ text }) => (
  <>
    <span className="recipe-ratings--loading-icon" />
    <span className="recipe-ratings--loading-text">{text}</span>
  </>
);

RateRecipeSpinner.propTypes = {
  text: PropTypes.string.isRequired,
};

const RateRecipeButton = ({ onClick, children }) => (
  <button
    className="recipe-ratings__rate-button gel-long-primer-bold"
    onClick={onClick}
    type="button"
  >
    {children}
  </button>
);

RateRecipeButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

const RateRecipeCta = ({ onClick, status, firstRating }) => {
  let ratingContent;

  switch (status) {
    case RATING_STATUS.LOADING:
      ratingContent = <RateRecipeSpinner text="Loading" />;
      break;
    case RATING_STATUS.PENDING:
      ratingContent = <RateRecipeSpinner text="Rating" />;
      break;
    case RATING_STATUS.RATED:
      ratingContent = <span role="alert">Thanks for rating!</span>;
      break;
    case RATING_STATUS.FAILED:
      ratingContent = (
        <span role="alert">
          <RateRecipeButton onClick={onClick}>
            Unfortunately your rating could not be submitted. Please try again
          </RateRecipeButton>
        </span>
      );
      break;
    default:
      ratingContent = firstRating ? (
        <RateRecipeButton onClick={onClick}>
          <StarIconUnrated />
          Be the first to rate this recipe
        </RateRecipeButton>
      ) : (
        <RateRecipeButton onClick={onClick}>Rate this recipe</RateRecipeButton>
      );
  }
  return <div className="recipe-ratings--cta gel-pica">{ratingContent}</div>;
};

RateRecipeCta.propTypes = {
  onClick: PropTypes.func.isRequired,
  status: PropTypes.oneOf(Object.values(RATING_STATUS)).isRequired,
  firstRating: PropTypes.bool,
};

RateRecipeCta.defaultProps = {
  firstRating: true,
};

export default RateRecipeCta;
