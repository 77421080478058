import React from 'react';
import PropTypes from 'prop-types';

import Image from '../generic/Image';
import Video from '../generic/Video';
import { videoShape } from '../generic/shapes';

if (process.env.CLIENT) {
  require('./MediaAsset.scss'); // eslint-disable-line global-require
}

export const MediaAsset = ({ isAMP, recipe }) => {
  const imageUrl = recipe.hasImage
    ? `food/ic/food_16x9_{width}/recipes/${recipe.id}_16x9.jpg`
    : recipe.video && recipe.video.image;

  const MediaAssetImage = (
    <Image alt={recipe.title} className="recipe-media__image" isLazy={false} src={imageUrl} />
  );

  const { video } = recipe;
  if (video) video.image = imageUrl;

  return (
    <div className="recipe-media">
      {video && video.versionPid && !isAMP && (
        <>
          <Video {...video} />
          <div className="recipe-media__placeholder-image">{MediaAssetImage}</div>
        </>
      )}
      {recipe.hasImage && (!video || isAMP || (video && !video.versionPid)) && MediaAssetImage}
    </div>
  );
};

MediaAsset.defaultProps = {
  isAMP: false,
};

MediaAsset.propTypes = {
  isAMP: PropTypes.bool,
  recipe: PropTypes.shape({
    id: PropTypes.string,
    hasImage: PropTypes.bool,
    title: PropTypes.string,
    video: PropTypes.shape(videoShape),
  }).isRequired,
};

export default MediaAsset;
