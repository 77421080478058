import React from 'react';
import PropTypes from 'prop-types';

import { chefDetailsShape } from './shapes';
import Image from '../generic/Image';

if (process.env.CLIENT) {
  require('./Chef.scss'); // eslint-disable-line global-require
}

const Chef = ({ chefDetails }) => (
  <div className="chef gel-brevier">
    {chefDetails.hasImage && (
      <a
        className="chef__image-link"
        href={`/food/chefs/${chefDetails.id}`}
        title={chefDetails.name}
      >
        <Image
          className="chef__image"
          height="56"
          isResponsive={false}
          src={`https://ichef.bbci.co.uk/food/ic/food_1x1_72/chefs/${chefDetails.id}_1x1.jpg`}
          width="56"
        />
      </a>
    )}
    <div className="chef__about">
      <div className="chef__name">
        <span className="gel-brevier-bold">By</span>{' '}
        <a className="chef__link" href={`/food/chefs/${chefDetails.id}`}>
          {chefDetails.name}
        </a>
      </div>
      {chefDetails.programme && (
        <div className="chef__programme-name">
          <span className="chef__preposition">From</span>{' '}
          <a className="chef__link" href={`/food/programmes/${chefDetails.programme.id}`}>
            {chefDetails.programme.title}
          </a>
        </div>
      )}
    </div>
  </div>
);

Chef.propTypes = {
  chefDetails: PropTypes.shape(chefDetailsShape),
};

Chef.defaultProps = {
  chefDetails: {},
};

export default Chef;
