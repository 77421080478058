import React from 'react';
import PropTypes from 'prop-types';

import AddToFavourites from '../favourite/AddToFavourites';
import ShareComponent from '../share/ShareComponent';

if (process.env.CLIENT) {
  require('./RecipeActions.scss'); // eslint-disable-line global-require
}

const RecipeActions = ({ isAMP, recipe }) => (
  <div className="recipe-actions gel-layout">
    {!isAMP && <ShareComponent />}

    {!isAMP && <AddToFavourites recipeId={recipe.id} />}

    <div className="recipe-actions-wrap gel-layout__item gel-1/1 gel-1/2@m gel-long-primer">
      <p className="my-favourites-recipes__start-adding recipe-actions-text--button">
        <a
          className="recipe-actions-link__button"
          href={`/food/recipes/${recipe.id}/shopping-list`}
        >
          <span className="recipe-actions__shopping-list-icon" />
          Shopping list
        </a>
      </p>
    </div>

    {!isAMP && (
      <div className="recipe-actions-wrap gel-layout__item gel-1/1 gel-1/2@m gel-long-primer">
        <div className="my-favourites-recipes__start-adding recipe-actions-text--button">
          <button
            className="recipe-actions-link__button"
            id="print-recipe-button"
            name="printButton"
            onClick={() => {
              window.print();
            }}
            type="button"
          >
            <span className="recipe-actions__print-link-icon" />
            Print recipe
          </button>
        </div>
      </div>
    )}
  </div>
);

RecipeActions.defaultProps = {
  isAMP: false,
};

RecipeActions.propTypes = {
  isAMP: PropTypes.bool,
  recipe: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default RecipeActions;
