import React from 'react';

const StarIconUnrated = () => (
  <svg
    className="star-rating__icon star-rating__icon--unrated"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
  >
    <path d="M25.89 30.44L16 24.59l-9.89 5.85 2.51-11.21L0 11.63l11.44-1.08L16 0l4.56 10.55L32 11.63l-8.62 7.6zM16 21.01l5.33 3.15-1.35-6 4.65-4.09-6.17-.58L16 7.78l-2.46 5.68-6.16.58 4.65 4.09-1.35 6z" />
  </svg>
);

const StarIconRated = () => (
  <svg
    className="star-rating__icon star-rating__icon--rated"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
  >
    <path d="M16 0l4.56 10.55L32 11.62l-8.62 7.6 2.51 11.21L16 24.58l-9.89 5.85 2.51-11.21L0 11.62l11.44-1.07L16 0z" />
  </svg>
);

const StarIconHalf = () => (
  <svg
    className="star-rating__icon star-rating__icon--half"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
  >
    <path d="M16 0v24.58l-9.89 5.85 2.51-11.21L0 11.62l11.44-1.07L16 0z" />
    <path d="M25.89 30.44L16 24.59l-9.89 5.85 2.51-11.21L0 11.63l11.44-1.08L16 0l4.56 10.55L32 11.63l-8.62 7.6zM16 21.01l5.33 3.15-1.35-6 4.65-4.09-6.17-.58L16 7.78l-2.46 5.68-6.16.58 4.65 4.09-1.35 6z" />
  </svg>
);

export { StarIconRated, StarIconUnrated, StarIconHalf };
