import React from 'react';
import PropTypes from 'prop-types';
import { openWindow } from './lib/SocialMediaWindow';

import ShareButton from './ShareButton';

const FacebookIcon = (
  <span className="ec-share-icon__svg ec-share-icon__svg--facebook">
    <svg focusable="false" viewBox="0 0 32 32">
      <path d="M12.1 32V17H8v-5.4h4.1V7c0-3.6 2.4-7 8-7 2.2 0 3.9.2 3.9.2l-.1 5.1h-3.6c-2 0-2.3.9-2.3 2.4v3.9h6l-.3 5.4H18v15h-5.9z" />
    </svg>
  </span>
);

const Facebook = ({ caption, description, link, name, picture, onClickCallback }) => (
  <ShareButton
    icon={FacebookIcon}
    name="Facebook"
    onClick={() => {
      if (onClickCallback) onClickCallback();

      let url =
        'https://www.facebook.com/dialog/feed?app_id=58567469885&redirect_uri=http%3A%2F%2Fwww.bbc.co.uk%2Fmodules%2Fshare%2Fclose&display=popup';

      if (description) url += `&description=${encodeURIComponent(description)}`;
      if (caption) url += `&caption=${encodeURIComponent(caption)}`;
      if (picture) url += `&picture=${encodeURIComponent(picture)}`;
      if (name) url += `&name=${encodeURIComponent(name)}`;

      url += `&link=${encodeURIComponent(link)}`;
      openWindow(url);
    }}
  />
);

Facebook.defaultProps = {
  description: undefined,
  picture: undefined,
  name: undefined,
  caption: undefined,
  onClickCallback: undefined,
};

Facebook.propTypes = {
  link: PropTypes.string.isRequired,
  description: PropTypes.string,
  picture: PropTypes.string,
  name: PropTypes.string,
  caption: PropTypes.string,
  onClickCallback: PropTypes.func,
};

export default Facebook;
