import React from 'react';
import PropTypes from 'prop-types';

if (process.env.CLIENT) {
  require('./Description.scss'); // eslint-disable-line global-require
}

const Description = ({ description }) => (
  <div className="recipe-description">
    <p
      className="recipe-description__text"
      dangerouslySetInnerHTML={{ __html: description.replace(/\r\n|\n/, '<br/><br/>') }}
    />
  </div>
);

Description.propTypes = {
  description: PropTypes.string.isRequired,
};

export default Description;
