/* eslint-disable react/no-invalid-html-attribute */
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { recipeShape, howToVideoShape } from './shapes';
import { seasonalPromoShape } from '../generic/seasonalPromo/shapes';
import ExtraInformation from './ExtraInformation';
import HowToVideo from './HowToVideo';
import Chef from './Chef';
import Description from './Description';
import IngredientsList from './IngredientsList';
import IsFromProgramme from './IsFromProgramme';
import IsFromMenu from './IsFromMenu';
import QuickLinks from './QuickLinks';
import MediaAsset from './MediaAsset';
import Metadata from './Metadata';
import MethodsList from './MethodsList';
import Ratings from './ratings/Ratings';
import RecipeActions from './RecipeActions';
import RecipeTitle from './Title';
import RelatedRecipes from './RelatedRecipes';
import SearchLink from './SearchLink';
import SeasonalPromoBlock from '../generic/seasonalPromo/SeasonalPromoBlock';
import SocialPromo from './SocialPromo';
import MetaProperties from '../generic/MetaProperties';

/* eslint-disable global-require */
if (process.env.CLIENT) {
  require('./Page.scss');
}
/* eslint-enable global-require */

const hasQuickLinkContent = (howToVideos = [], recipe = {}) =>
  howToVideos.length > 0 || recipe.tips || recipe.wines;

const getCanonical = (baseURL, recipe) =>
  `${baseURL}/food/recipes/${recipe.canonical ? recipe.canonical : recipe.id}`;

const getMetaImage = (cdnUrl, ichefBasePath, recipe) =>
  recipe.hasImage
    ? `${ichefBasePath}food/ic/food_16x9_448/recipes/${recipe.id}_16x9.jpg`
    : `${cdnUrl}images/non-spriteable-images/bbc_placeholder.png`;

export const RecipeLeadingInfo = ({ isAMP, recipe }) => (
  <div className="recipe-leading-info">
    <div className="gel-layout">
      <div className="gel-layout__item gel-1/1 gel-3/4@l">
        {(recipe.hasImage || recipe.video) && <MediaAsset isAMP={isAMP} recipe={recipe} />}

        <div className="recipe-leading-info__main-metadata">
          <Metadata metadata={recipe.metadata} diets={recipe.diets} />
        </div>

        {recipe.description && <Description description={recipe.description} />}

        {recipe.chefDetails && recipe.chefDetails.name && <Chef chefDetails={recipe.chefDetails} />}

        <RecipeActions isAMP={isAMP} recipe={recipe} />
      </div>
      <div className="gel-layout__item gel-1/4 recipe-leading-info__side-bar">
        <Metadata metadata={recipe.metadata} diets={recipe.diets} />
      </div>
    </div>
  </div>
);

RecipeLeadingInfo.propTypes = {
  isAMP: PropTypes.bool,
  recipe: PropTypes.shape(recipeShape).isRequired,
};

RecipeLeadingInfo.defaultProps = {
  isAMP: false,
};

export const RecipePageMetadata = ({ baseURL, cdnUrl, ichefBasePath, recipe }) => {
  const canonical = getCanonical(baseURL, recipe);
  const metaImageURL = getMetaImage(cdnUrl, ichefBasePath, recipe);
  return (
    <>
      <MetaProperties
        title={`${recipe.metaTitle} recipe`}
        description={recipe.metaDescription}
        canonical={canonical}
        imageUrl={metaImageURL}
        imageWidth="448"
        imageHeight="224"
      />
      <Helmet>
        <link rel="amphtml" href={`${canonical}/amp`} />
      </Helmet>
    </>
  );
};

RecipePageMetadata.propTypes = {
  baseURL: PropTypes.string.isRequired,
  cdnUrl: PropTypes.string.isRequired,
  ichefBasePath: PropTypes.string.isRequired,
  recipe: PropTypes.shape(recipeShape).isRequired,
};

export const RecipePage = ({
  baseURL,
  cdnUrl,
  ichefBasePath,
  hasRecipeIsFrom,
  howToVideos,
  isAMP,
  recipe,
  relatedRecipes,
  seasonalPromo,
  foodImagesPath,
}) => (
  <>
    <RecipePageMetadata
      baseURL={baseURL}
      cdnUrl={cdnUrl}
      ichefBasePath={ichefBasePath}
      recipe={recipe}
    />

    {isAMP && (
      <div className="gel-wrap recipe-finder-link__wrap">
        <div className="gel-layout">
          <SearchLink isAMP={isAMP} recipe={recipe} />
        </div>
      </div>
    )}

    <div className="recipe-wrap gel-wrap">
      <div className="gel-layout">
        <div className="recipe-main-info gel-layout__item gel-1/1 gel-2/3@l">
          <RecipeTitle recipe={recipe} />

          <Ratings isAMP={isAMP} recipeId={recipe.id} />

          <RecipeLeadingInfo isAMP={isAMP} recipe={recipe} />

          <IngredientsList stages={recipe.stages} />

          {!isAMP && hasQuickLinkContent(howToVideos, recipe) && (
            <QuickLinks tips={recipe.tips} wines={recipe.wines} howToVideos={howToVideos} />
          )}

          <div
            className={classNames('gel-layout recipe-method-list', {
              'recipe-method--no-links': !hasQuickLinkContent(howToVideos, recipe),
            })}
          >
            <MethodsList methods={recipe.methods} />
          </div>

          {recipe.tips && (
            <ExtraInformation id="recipe-tips" icon="tips" text={recipe.tips} title="Recipe Tips" />
          )}

          {!isAMP && howToVideos.length > 0 && (
            <ExtraInformation id="recipe-how-to-videos" icon="how-to-videos" title="How-to videos">
              <HowToVideo howToVideos={howToVideos} />
            </ExtraInformation>
          )}

          {recipe.wines && (
            <ExtraInformation id="recipe-wine" icon="wines" text={recipe.wines} title="Wines" />
          )}
        </div>

        <div className="grid-list-wrapper gel-layout__item gel-1/1 gel-1/3@l">
          {seasonalPromo && (
            <SeasonalPromoBlock season={seasonalPromo} foodImagesPath={foodImagesPath} />
          )}

          {relatedRecipes && relatedRecipes.length > 0 && (
            <div className="grid-list-panel--dark">
              <div className="grid-list-panel__item">
                <RelatedRecipes relatedRecipes={relatedRecipes} />
              </div>
            </div>
          )}

          {hasRecipeIsFrom && (
            <div className="grid-list-panel--dark">
              <div className="grid-list-panel__item">
                <IsFromProgramme
                  programmeEpisode={recipe.programmeEpisode}
                  programmeSeries={recipe.programmeSeries}
                  programmeTopLevel={recipe.programmeTopLevel}
                  recipesFromThisEpisode={recipe.recipesFromThisEpisode}
                  title={recipe.title}
                />
              </div>
            </div>
          )}

          {recipe.recipeIsPartOfMenu && (
            <div className="grid-list-panel--dark">
              <IsFromMenu recipeIsPartOfMenu={recipe.recipeIsPartOfMenu} />
            </div>
          )}

          <div className="social-promo">
            <SocialPromo />
          </div>
        </div>
      </div>
    </div>
  </>
);

RecipePage.propTypes = {
  baseURL: PropTypes.string.isRequired,
  cdnUrl: PropTypes.string.isRequired,
  ichefBasePath: PropTypes.string.isRequired,
  hasRecipeIsFrom: PropTypes.bool.isRequired,
  howToVideos: PropTypes.arrayOf(PropTypes.shape(howToVideoShape)).isRequired,
  isAMP: PropTypes.bool,
  recipe: PropTypes.shape(recipeShape).isRequired,
  seasonalPromo: PropTypes.shape(seasonalPromoShape),
  foodImagesPath: PropTypes.string.isRequired,
  relatedRecipes: PropTypes.array, // eslint-disable-line react/forbid-prop-types
};

RecipePage.defaultProps = {
  isAMP: false,
  seasonalPromo: undefined,
  relatedRecipes: undefined,
};

export const AMP = {
  components: [
    `recipe/**/*.js`,
    `navigation/mobile/**/*.js`,
    `page/*.js`,
    `generic/seasonalPromo/SeasonalPromoBlock.js`,
  ],
  name: 'recipePage',
  whitelist: ['main-menu__item--recipes', 'recipe-ingredients__link', 'seasonal-promo__block'],
};

export default connect(state => ({
  baseURL: state.pageReducer.baseURL,
  cdnUrl: state.pageReducer.cdnUrl,
  ichefBasePath: state.pageReducer.ichefBasePath,
  hasFavouritedRecipe: state.favouriteReducer.hasFavouritedRecipe,
  hasRecipeIsFrom: state.recipeReducer.hasRecipeIsFrom,
  howToVideos: state.recipeReducer.howToVideos,
  isAMP: state.pageReducer.isAMP,
  seasonalPromo: state.pageReducer.seasonalPromo,
  recipe: state.recipeReducer.recipe,
  relatedRecipes: state.recipeReducer.relatedRecipes,
  foodImagesPath: state.pageReducer.foodImagesPath,
}))(RecipePage);
